<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        ¿Tienes licencia de conducir vigente?
      </div>
    </div>

    <ValidationObserver ref="formstepPiG" tag="div"  class="ppi_contentZone spacerRowTop20" v-slot="{ handleSubmit, valid, passed }">
      <q-form 
        ref="myFormPiG"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPigReset"
        class="formDetailZone "
      >
        <ValidationProvider 
          tag="div"
          name="tiene_licencia"
          ref="fldx_tienelicencia" :immediate="true" 
          class="canna_forma_campo_container" style="margin-left: -10px;"
          :rules=" { required: true, oneOf: z_allowTieneLic } "
          v-slot="{ errors, invalid, validated  }"
        >
            <input type="hidden" name="tiene_licencia"  v-model="frmData.tienes_licencia">
            <q-option-group
              class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
              inline
              name="tienelicenciaOpt"
              type="radio"
              keep-color
              :options="[
                { label: 'Si', value: 95, color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                { label: 'No', value: 96 , color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }
              ]"
              :color="(invalid && validated) ? 'positive' : 'canna_ColorRadio'"
              v-model="frmData.tienes_licencia"
            />
            <template v-if="(invalid && validated && errors[0] !==undefined)">
              <div class="q-field--error q-field__bottom" 
                style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
              >
               {{ errors[0] }}
              </div>
            </template>
        </ValidationProvider>
      
        <!-- tipo licencia -->
        <div class="dataSection_container" v-show="(frmData.tienes_licencia===95)">
          <div class="datasectionItem dataSection_separadorx" style="margin-bottom: 0px !important;padding-bottom: 0px !important;">
            <div class="row">
              <div class="col col-xs-12 col-md-6 canna_forma_campo_container" style="padding-bottom: 0px;"
                :style=" ( is_Mobile === true || $q.screen.lt.md ) ? '':' max-width:360px !important;'" 
              ><!-- padding-left: 8px; -->

                <ValidationProvider
                  ref="fldx_tipolic" name="tipolicencia" :immediate="false" 
                  :rules=" { required: (frmData.tienes_licencia===95) ? true : false, oneOf: z_allowTipoLic }"
                  v-slot="{ errors, invalid, validated  }"
                >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                        Tipo de licencia
                      </div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <q-select
                      name="tipolicencia"
                      v-model="frmData.tipo_licencia"
                      filled
                      :dense="true"
                      :label="(frmData.tipo_licencia ==='' || frmData.tipo_licencia === null) ? 'Elige una opción' : ''"
                      :options="cat_tipoLic.dataCatalog"
                      option-value="id"
                      option-label="nombre"
                      emit-value
                      map-options
                      :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ? errors[0].replace('tipolicencia','Tipo de licencia').replace('El campo','') : '' "
                    >
                      <template v-slot:append>
                        <q-icon v-if="frmData.tipo_licencia !== null" class="cursor-pointer" name="clear" @click.stop="frmData.tipo_licencia = null" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label>
                              {{scope.opt.nombre}}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>

                    </q-select>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>

        <!-- tiene vigencia perm ? -->
        <div class="dataSection_container" style="margin-left: -10px;" v-show="(frmData.tienes_licencia===95)">
          <div class="datasectionItem dataSection_separadorx" style="margin-bottom: 0px !important;padding-bottom: 0px !important;">
            <div class="row">
              <div class="col-12 col-xs-12 col-md-12 canna_forma_campo_container" style="padding-bottom: 0px;"
                :style=" ( is_Mobile === true || $q.screen.lt.md ) ? '':' max-width:360px !important;'" 
              >
                <!-- Campo de vigencia permanente -->
                <ValidationProvider
                  ref="fldx_vigencia" name="tienevigencia" :immediate="false" 
                  :rules=" { required: (frmData.tienes_licencia===95) ? true : false, oneOf:[754,755] } " 
                   v-slot="{ errors, invalid, validated  }"
                >
                  <q-checkbox 
                    name="tienevigencia" v-model="frmData.vigencia.perm" :label="'Vigencia permanente'"
                    :true-value="754" :false-value="755"
                    color="cannaMainColor" 
                  />
                  <template v-if="(invalid && validated && errors[0] !==undefined)">
                    <div class="q-field--error q-field__bottom" style="display:none">
                      {{ errors[0] }}
                    </div>
                  </template>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>

        <!--  fecha de vigencia -->
        <div class="dataSection_container" style="margin-left:0px;" v-if="(frmData.tienes_licencia===95)">
          <div class="datasectionItem dataSection_separadorx" style="margin-bottom: 0px !important;padding-bottom: 0px !important;">

            <ValidationProvider
              ref="fldx_vigencia" name="fecha_vigencia" 
              :immediate="true"
              :rules=" { 
                required: (frmData.tienes_licencia === 95 && frmData.vigencia.perm === 755) ? true : false,
                cannaQuest_DateIsValid:true 
              } " 
              v-slot="{ errors, invalid, validated  }"
              tag="div" class="row q-gutter-md"
            >
              <div class="col-12" style="display: flex;justify-content: space-between;">
                <div class="fieldLabel labelFx_Field text-left noselect"
                  :class="{'fieldError':invalid && validated }"
                >Vigencia</div>
                <div class="requiredLabel text-right" >&nbsp;</div>
              </div>

              <input type="hidden" name="fecha_vigencia"  v-model="frmData.vigencia.fecha">
              <!-- año list_dtRefMeses -->
              <div class="" style="margin-top: 2px !important;"
                :class="( is_Mobile === true || $q.screen.lt.sm ) ? 'col-12' : 'col'"
                :style="( is_Mobile === true || $q.screen.lt.sm ) ? '' : 'max-width: 145px;'"
              >
                <q-select
                  :disable="(frmData.vigencia.perm===754) ? true : false"
                  name="vigenciayear"
                  filled
                  :dense="true"
                  v-model="frmData.vigencia.year"
                  :label="(frmData.vigencia.year ==='' || frmData.vigencia.year === null) ? 'Año' : ''"
                  :options="catdata_years"
                  option-value="value"
                  option-label="label"
                  emit-value
                  map-options
                  :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                  :error="invalid && validated"
                  @input="(val, evt) => { pig_doCheckFechas('year',val,evt) }"
                >
                <!--  :error-message="(errors[0] !==undefined) ? errors[0].replace('tipolicencia','Tipo de licencia').replace('El campo','') : '' " -->

                  <template v-slot:append>
                    <q-icon name="clear" class="cursor-pointer" v-if="frmData.vigencia.year !== null" @click.stop="frmData.vigencia.year = null"/>
                  </template>

                  <template v-slot:option="scope">
                    <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                      <q-item-section>
                        <q-item-label>
                          {{scope.opt.label}}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </template>

                </q-select>
              </div>

              <!-- mes -->
              <div class="canna_forma_campo_container" style="margin-top: 2px !important;"
                :class="( is_Mobile === true || $q.screen.lt.sm ) ? 'col-12' : 'col'"
                :style="( is_Mobile === true || $q.screen.lt.sm ) ? '' : 'max-width: 180px;'"
              >
                <q-select
                  :disable="(frmData.vigencia.perm===754) ? true : false"
                  name="vigenciames"
                  filled
                  :dense="true"
                  v-model="frmData.vigencia.month"
                  :label="(frmData.vigencia.month ==='' || frmData.vigencia.month === null) ? 'Mes' : ''"
                  :options="list_refMesesSelect"
                  option-value="value"
                  option-label="label"
                  emit-value
                  map-options
                  :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                  :error="invalid && validated"
                  @input="(val, evt) => { pig_doCheckFechas('mes',val,evt) }"
                >
                <!--  :error-message="(errors[0] !==undefined) ? errors[0].replace('tipolicencia','Tipo de licencia').replace('El campo','') : '' " -->

                  <template v-slot:append>
                    <q-icon name="clear" class="cursor-pointer" v-if="frmData.vigencia.month !== null" @click.stop="frmData.vigencia.month = null"/>
                  </template>

                  <template v-slot:option="scope">
                    <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                      <q-item-section>
                        <q-item-label>
                          {{scope.opt.label}}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </template>

                </q-select>
              </div>

              <!-- dia -->
              <div class="canna_forma_campo_container" style=" margin-top: 2px !important;"
                :class="( is_Mobile === true || $q.screen.lt.sm ) ? 'col-12' : 'col'"
                :style="( is_Mobile === true || $q.screen.lt.sm ) ? '' : 'max-width: 130px;'"
              >
                <q-select
                  :disable="(frmData.vigencia.perm===754) ? true : false"
                  name="vigenciaday"
                  filled
                  :dense="true"
                  v-model="frmData.vigencia.day"
                  :label="(frmData.vigencia.day ==='' || frmData.vigencia.day === null) ? 'Día' : ''"
                  :options="catdata_dias"
                  option-value="value"
                  option-label="label"
                  emit-value
                  map-options
                  :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                  :error="invalid && validated"
                  @input="(val, evt) => { pig_doCheckFechas('dia',val,evt) }"
                >
                <!--  :error-message="(errors[0] !==undefined) ? errors[0].replace('tipolicencia','Tipo de licencia').replace('El campo','') : '' " -->

                  <template v-slot:append>
                    <q-icon name="clear" class="cursor-pointer" v-if="frmData.vigencia.day !== null" @click.stop="frmData.vigencia.day = null"/>
                  </template>

                  <template v-slot:option="scope">
                    <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                      <q-item-section>
                        <q-item-label>
                          {{scope.opt.label}}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </template>

                </q-select>
              </div>

              <template v-if="(invalid && validated && errors[0] !==undefined)">
                <div class="col-12" style="margin-top: 0px;">
                  <div class="q-field--error q-field__bottom" 
                    style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                  >
                  {{ errors[0].replace('fecha_vigencia', 'La fecha de vigencia').replace('El campo','').replace('obligatorio','obligatoria') }}
                  </div>
                </div>
              </template>

            </ValidationProvider>
          </div>
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../../boot/axios' // APP code 
import logOut_Mixin from '../../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import { helperCatsFechas_Mixin } from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

const tplpig_Data = {
  tienes_licencia: 95, //95 = SI  // 96 = NO 
  tipo_licencia: null,
  vigencia:{
    perm: 755,  // 754 = SI //755 = no 
    fecha: '',
    year: null, month:null, day: null
  }
}

export default {
  name: 'psi_screen_licencia',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    helperCatsFechas_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-------------
      zloadingData: false,
      processingData: false,
      //-----------
      zz_valuesTocheckIfNO: [
        96,  // No de licencia 
        154, 155, 156, 157, //Tipo de licencia 
        754, 755 
      ],
      //-----------
      catdata_dias: [],
      catdata_years: [],
      //-----------
      z_allowTieneLic: [],
      z_allowTipoLic: [],
      z_allowVigencia: [],
      profileRecords:[], //Los registros previamente guardados en este perfil (solo uno)
      frmDataOriginal: JSON.parse(JSON.stringify(tplpig_Data)) ,
      frmData: JSON.parse(JSON.stringify(tplpig_Data))
    }
  },
  computed: {
    cat_tieneLic () { // catalogo id = 12 Tiene Licencia (si:95 no:96)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(12) : this.getHelperCat(12)
    },
    cat_tipoLic () { // catalogo id = 23 Tipo Licencia
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(23) : this.getHelperCat(23)
    },
    cat_vigenciaPerm () { // catalogo id = 58 Tiene Licencia Permanente (si:754 no:755)  NOTA en  NO guardar otro : fehca de vigencia 
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(58) : this.getHelperCat(58)
    },
    mainFormTest (){
      if (this.$refs.formstepPiG) {
        return this.$refs.formstepPiG
      } else { 
        return null 
      }
    },
    mainErrors () {
      var countx = 0
      if (this.zinitScreen) {
        try {
          if (this.$refs.formstepPiG) {
            if (this.$refs.formstepPiG.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiG.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiG.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {
    'frmData.tienes_licencia': function(newVal, oldVal){
      if(newVal!==oldVal){
        if (newVal === 96){ // NO 

          this.frmData.tipo_licencia = null
          this.frmData.vigencia = {
            perm: 755,  // 754 = SI //755 = no 
            fecha: '',
            year: null, month:null, day: null
          }
          if (this.$refs.fldx_vigencia){
            this.$refs.fldx_vigencia.reset()
          }
          this.$forceUpdate()

        } else {  // SI 
          if (this.$refs.fldx_tipolic){
            this.$refs.fldx_tipolic.validate()
          }
          if (this.$refs.fldx_vigencia){
            this.$refs.fldx_vigencia.validate()
          }
          this.$forceUpdate()
        }
      }
    },
    'frmData.vigencia.perm': function(newVal, oldVal){
      if(newVal!==oldVal){
        if (newVal === 754){
          this.frmData.vigencia.year = null
          this.frmData.vigencia.month = null
          this.frmData.vigencia.day = null
          this.$forceUpdate()
        }
      }
    },
    'frmData.vigencia.year': function(newVal, oldVal){
      if(newVal!==oldVal){ 
        this.pig_updateFechaVigencia()
      }
    },
    'frmData.vigencia.day': function(newVal, oldVal){
      if(newVal!==oldVal){ 
        this.pig_updateFechaVigencia()
      }
    },
    'frmData.vigencia.month': function(newVal, oldVal){
      if(newVal!==oldVal){ 
        if (newVal === '' || newVal === null || newVal ==undefined ){ 
          this.pig_updateDiasList(null)
          this.$forceUpdate()
        } else { 
          this.pig_updateDiasList(newVal)
          this.$forceUpdate()
        }
        this.pig_updateFechaVigencia()
      }
    }
  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_licencia -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/direccion-b'
      this.$cannaDebug('-- psiPage_licencia -- do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    onStepFormPigReset (){ 

    },
    pig_doCheckFechas(){ 
      if (this.$refs.fldx_vigencia){
        this.$refs.fldx_vigencia.validate()
      }
    },
    pig_updateFechaVigencia() {
      var fullfecha = ''
      if (this.frmData.vigencia.year !== null ){
        fullfecha+= this.frmData.vigencia.year
        if (this.frmData.vigencia.month !== null ){
          fullfecha+= '-' 
          fullfecha+= (this.frmData.vigencia.month<=9) ? '0' + this.frmData.vigencia.month : this.frmData.vigencia.month
          if (this.frmData.vigencia.day !== null ){
            fullfecha+= '-' 
            fullfecha+= (this.frmData.vigencia.day<=9) ? '0' + this.frmData.vigencia.day : this.frmData.vigencia.day
          }
        }
      }
      this.frmData.vigencia.fecha = fullfecha
      this.$forceUpdate()
    },
    pig_createYearDataList(){ 
      var myDataYears = []
      var fechax = new Date()
      var yearinitial = fechax.getUTCFullYear()
      myDataYears.push({ label:'' + yearinitial , value: yearinitial})
      for (var i=1;i<=10;i++){
        myDataYears.push({ label:'' + (yearinitial + i) , value: (yearinitial + i)})
      }
      this.catdata_years = myDataYears
    },
    pig_updateDiasList( monthX ) {
      var myDataDays = []
      var endDays = 28
      if ( monthX === null || monthX === '' || monthX ===undefined  ){ 
        endDays = 31
      } else if (monthX === 2){
        endDays = 28
      } else if ([1,3,5,7,8,10,12].indexOf(monthX)>=0) { 
        endDays = 31
      } else {  // 4,6,9,11
        endDays = 30
      }
      for (var i=1;i<=endDays;i++){
        myDataDays.push({ label:'' + (i) , value: (i)})
      }
      this.catdata_dias = myDataDays
    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_licencia -- do Submit Data --- ' + newRuta)
      var test = 11 
      var tmpArrTest
      var toAdd = [], toRemove = [], toPatch = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1) 
      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 

      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 ) { // Que existan valores...! 
        test = 22
        // tienes licencia
        if (testA._.tienes_licencia.status === 'MODIFIED' && testA._.tienes_licencia.current === 96 ) {
          // Si es no automatico se borran los otros valores  
          toAdd.push(testA._.tienes_licencia.current)

        } else if (
          (testA._.tienes_licencia.status === 'MODIFIED' && testA._.tienes_licencia.current === 95) || 
          (testA._.tienes_licencia.status === 'EQUAL' && testA._.tienes_licencia.current === 95) 
        ) { // Modificado q diga si  o la primera vez q no hay registros 

          // tienes licencia
          if (testA._.tienes_licencia.status === 'MODIFIED') {
            if (testA._.tienes_licencia.original === 96){
              toRemove.push(96)
            }
            toAdd.push(testA._.tienes_licencia.current)
          } else if (testA._.tienes_licencia.status === 'EQUAL' &&  this.profileRecords.length<=0) {
            toAdd.push(testA._.tienes_licencia.current)
          }
          // Tipo de licencia 
          if (testA._.tipo_licencia.status === 'MODIFIED') {
            if (testA._.tipo_licencia.original!==null){
              tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === testA._.tipo_licencia.original) // Tiene que existir
              if (tmpArrTest.length>=0){ 
                toRemove.push(testA._.tipo_licencia.original)
              }
            }
            toAdd.push(testA._.tipo_licencia.current)
          }

          // Vigencia
          if (testA._.vigencia._.perm.status === 'MODIFIED' || (testA._.vigencia._.perm.status === 'EQUAL') ) {
            test = 66
            if (testA._.vigencia._.perm.status === 'MODIFIED' && testA._.vigencia._.perm.current == 754) { 
              tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === 755) // Tiene que existir
              if (tmpArrTest.length>0){
                toRemove.push(755)
              }
              toAdd.push(testA._.vigencia._.perm.current)
            } else if (testA._.vigencia._.perm.status === 'MODIFIED' && testA._.vigencia._.perm.current == 755) { 
              tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === 754) // Tiene que existir
              if (tmpArrTest.length>0){
                toRemove.push(754)
              }
              toAdd.push({ catalogo_id: testA._.vigencia._.perm.current, otro: '' + this.frmData.vigencia.fecha })
            } else if (testA._.vigencia._.perm.status === 'EQUAL' && testA._.vigencia._.perm.current == 755 && testA._.vigencia._.fecha.status === 'MODIFIED') { 
              // Si se modifica la fecha de vigencia solamente o se agrega todo 
              tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === 755) // Tiene que existir
              if (tmpArrTest.length>0){
                toPatch.push([755, this.frmData.vigencia.fecha])
              } else { 
                toAdd.push({ catalogo_id: 755, otro: '' + this.frmData.vigencia.fecha })
              }
            }
          }
        }
        test = 100 
        // ------------------------------
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], patch: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, patch: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')
        
        // ----------------------
        // DAtos a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se hace path 
        if (toPatch.length > 0) {
          var toPatchReqs = []
          for (const pxRC of toPatch) {
            toPatchReqs.push(this.createAxiosConfig ('patch', mcModel, { 
              delimiters: [pxRC[0]],
              data: { otro: '' + pxRC[1] }
            }, true, null))
          }
          for (const patchRQx of toPatchReqs) {
            if (reqErr.patch <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataPatch', reqRes.patch, patchRQx).then((resx) => {
                var tmpresponseData = reqRes.patch[reqRes.patch.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.patch += 1
                }
              })
            }
          }
          if (reqErr.patch > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.patch[0], reqErr.patch)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se agrega 
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        // Actualizar Datos de Estatus // WIP 

        // -----------
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData))
        allowNextPage = true

      } else { // seguimos a la siguiente pantalla 

        test = 33
        allowNextPage = true

      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.LICENCIA){ // < 19
        await this.hpb_updateProfileStatus(this.zstatUser.LICENCIA).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/direccion-d'
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {

      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {categoria_id: '12,23,58', 'per-page': 'all', expand:'catalogo'}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpig_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpig_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))

      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores // alert('llenando datos')
        var tieneLic = null 
        var tipoLic = null 
        var vigenciaPErm = null 
        var vigenciaFecha = null
        var continueData = true 
        profileData.forEach(itemP => {
          if (continueData === true ){ 
            if (itemP.categoriaId === 12) { //operativos 
              if (itemP.catalogo_id === 96){
                continueData = false 
              }
              tieneLic = itemP.catalogo_id
            } else  if (itemP.categoriaId === 23) { //Tipo licencia
              tipoLic = itemP.catalogo_id
            } else  if (itemP.categoriaId === 58) { //administrativos
              vigenciaPErm = itemP.catalogo_id
              if (itemP.catalogo_id === 755){ 
                vigenciaFecha = itemP.otro
              }
            }
          }
        })

        var newData = {
          tienes_licencia: tieneLic, //95 = SI  // 96 = NO 
          tipo_licencia: null,
          vigencia:{
            perm: 755, // 754 = SI //755 = no 
            fecha: '',
            year: null, month:null, day: null
          }
        }
        if (tieneLic === 95){
          var fechasVals = []
          if (vigenciaPErm===755){
            fechasVals = vigenciaFecha.split('-') 
          }
          newData.tienes_licencia = 95 
          newData.tipo_licencia = tipoLic
          newData.vigencia = {
            perm: vigenciaPErm,  // 754 = SI //755 = no 
            fecha: (vigenciaPErm===754) ? '' : vigenciaFecha,
            year: (vigenciaPErm===754) ? null : parseInt(fechasVals[0]) ,
            month: (vigenciaPErm===754) ? null : parseInt(fechasVals[1]) ,
            day: (vigenciaPErm===754) ? null : parseInt(fechasVals[2])
          }
        }
        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

      } else { // No hay info todavia 
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpig_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpig_Data))
      }
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_licencia -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_licencia -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
    this.pig_createYearDataList()
    this.pig_updateDiasList(null)
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_licencia --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_licencia -- mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)

    if (this.$refs.fldx_vigencia){
      this.$refs.fldx_vigencia.reset()
    }
    if (this.$refs.fldx_tienelicencia){
      this.$refs.fldx_tienelicencia.reset()
    }

    if (this.getHelperCat(12).dataLoaded===false){ 
      var testCatA = false 
      await this.helpermix_getCatalog (12, null).then( respx => { 
        this.$cannaDebug('-- psiPage_puestos --   categoria 12 Tiene Licencia finish ')
        testCatA = respx 
      })
    }
    if (this.getHelperCat(23).dataLoaded===false){ 
      var testCatB = false 
      await this.helpermix_getCatalog (23, null).then( respx => { 
        this.$cannaDebug('-- psiPage_puestos --   categoria 23 Tipo Licencia finish ')
        testCatB = respx 
      })
    }

    if (this.getHelperCat(58).dataLoaded===false){ 
      var testCatB = false 
      await this.helpermix_getCatalog (58, null).then( respx => { 
        this.$cannaDebug('-- psiPage_puestos --   categoria 58 Tiene Licencia Permanente finish ')
        testCatB = respx 
      })
    }
    await this._waitRequestProc(200)

    var tieneLic_Vals = []
    var tipoLic_Vals = []
    var vigencia_Vals = []

    this.getHelperCat(12).dataCatalog.forEach( item => { 
      tieneLic_Vals.push(item.id)
    })
    this.getHelperCat(23).dataCatalog.forEach( item => { 
      tipoLic_Vals.push(item.id)
    })
    this.getHelperCat(58).dataCatalog.forEach( item => { 
      vigencia_Vals.push(item.id)
    })

    this.z_allowTieneLic = tieneLic_Vals
    this.z_allowTipoLic = tipoLic_Vals
    this.z_allowVigencia = vigencia_Vals

    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })

    if (this.$refs.fldx_tienelicencia){
      this.$refs.fldx_tienelicencia.reset()
    }
    if (this.$refs.fldx_vigencia){
      this.$refs.fldx_vigencia.reset()
    }

    this.$forceUpdate()

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_licencia -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_licencia -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_licencia -- activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      
      await this._waitRequestProc(200)
      var respInitData = null 

      await this.initFormData().then( respx => {
        respInitData = respx 
      })

      await this._waitRequestProc(200)

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_licencia -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_licencia -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_licencia -- destroyed')
  }
}
</script>
